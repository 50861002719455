@import "src/app/theme/styles/variables";

/******* Base styles *******/
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $FONT_FAMILY;
  font-size: 14px;
  line-height: 1.5;
}

* {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
}

.transition {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none $WHITE;
  border-radius: 0px;

  &:hover {
    background: #cccccc;
  }

  &:active {
    background: #888888;
  }
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none $WHITE;
  border-radius: 0px;

  &:hover {
    background: #666666;
  }

  &:active {
    background: #333333;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}
