/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "app/theme/styles/material";
@import "app/theme/styles/base";

.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.highlightText {
  color: $RED_HIGHLIGHT !important;
  font-weight: 700 !important;
}

.mat-action-row {
  padding: 16px !important;
}
