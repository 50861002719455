$FONT_FAMILY: Roboto, "Helvetica Neue", sans-serif;
$GAGGLE_ORANGE: #f97239;
$GAGGLE_PURPLE: #3b4170;
$RED_HIGHLIGHT: #ba2026;
$DARK_TEXT: #1e1e1e;
$LIGHT_TEXT: #687d86;
$WHITE: #ffffff;
$LINK_COLOR: #0061a3;
$GREEN_STATUS: #508c45;
$TRANSLATION_BLUE: #675cff;
$TRANSLATION_BACKGROUND: #ffffad;

$DISABLED_BUTTON_BG: #dcdcdc;
$DISABLED_BUTTON_TEXT: #a8a8a8;

$FLAGGED_MESSAGE_BACKGROUND: rgba(186, 32, 38, 0.16);
