@use "sass:map";
@use "@angular/material" as mat;

@import "@angular/material/theming";
@import "src/app/theme/styles/variables";

:host {
  margin-top: 15px;
  font-size: small;
}

@mixin file-comment-panel-theme($theme) {

  $foreground: map-get($theme, foreground);

  $DOT_SIZE: 0.8em;

  .section-title {
    color: $DARK_TEXT;
    margin-bottom: 15px;
  }

  .user-dot {
    font-size: 30px;
    border-radius: 50%;
    height: $DOT_SIZE;
    min-height: $DOT_SIZE;
    width: $DOT_SIZE;
    min-width: $DOT_SIZE;
    margin: 0.5em 0.25em;
    transition: transform 60ms linear;
    position: relative;

    &:after {
      content: attr(char-code);
      color: white;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.75), 1px 1px 3px rgba(0, 0, 0, 0.3);
      font-size: calc(#{$DOT_SIZE} * 0.75);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .comment {

    &.reply {
      border-left: 1px solid mat.get-color-from-palette($foreground, divider);
      margin-left: 20px;
    }

    &:hover {
      & > .details {
        color: $DARK_TEXT;
      }
    }

    & > .details {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $LIGHT_TEXT;

      .spacer {
        flex-grow: 1;
      }
    }

    & > .content {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      & > .text {
        padding: 10px;
        margin: 5px 0;
        border-radius: 5px;
        color: $DARK_TEXT;
        background-color: rgba(0, 0, 0, 0.04);

        &.flagged {
          background-color: $FLAGGED_MESSAGE_BACKGROUND;
        }
      }

      &:hover {
        .translate {
          display: flex;
        }
      }

      .translate {

        &.translated {
          display: flex;
        }

        display: none;
        margin-left: 10px;
        flex-direction: row;
        align-items: center;

        gaggle-icon {
          color: $TRANSLATION_BLUE;
          cursor: pointer;

          &.translated {
            color: $DISABLED_BUTTON_TEXT;
            cursor: not-allowed;
          }
        }

        .translated-text {
          margin-left: 10px;
          padding: 10px;
          border-radius: 5px;
          color: $DARK_TEXT;
          background: $TRANSLATION_BACKGROUND;
        }
      }
    }
  }

  .participants {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    color: $LIGHT_TEXT;

    & > .divider {
      border-top: 1px solid mat.get-color-from-palette($foreground, divider);
      height: 1em;
      width: 4em;
      margin-right: auto;
    }

    & > .list {
      margin: 0.5em 0;
    }
  }
}
